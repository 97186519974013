.stgy-wrapper {
    display: flex;
    min-height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    font-family: var(--stgy-font-family);
    font-size: var(--stgy-font-size-medium);
    font-weight: var(--stgy-font-weight-normal);
  
  
    /*
    Select  
    */
    select{
      user-select: none;
      appearance: none;
      background-color: red !important;
      margin: 0;
      width: 100%;
      height: 2.5rem;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      border: 1px solid var(--stgy-gray-200);
      border-radius: 0.25rem;
      box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
      padding: 0.5rem;
      font-size: var(--stgy-font-size-small);
      background: var(--stgy-white) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMS45MDg0IDE1Ljc2NTZMNi45MDc0OCAxMC45NjU2QzYuNzQ0OTUgMTAuODA5NiA2LjY2MzI3IDEwLjYwNDggNi42NjMyNyAxMC40QzYuNjYzMjcgMTAuMTk1MiA2Ljc0NDk1IDkuOTkwMzkgNi45MDc0OCA5LjgzNDM5QzcuMjMzMzggOS41MjE1OSA3Ljc2MDE0IDkuNTIxNTkgOC4wODYwMyA5LjgzNDM5TDEyLjQ5NzcgMTQuMDY4OEwxNi45MDkzIDkuODM0MzlDMTcuMjM1MiA5LjUyMTU5IDE3Ljc2MiA5LjUyMTU5IDE4LjA4NzkgOS44MzQzOUMxOC40MTM4IDEwLjE0NzIgMTguNDEzOCAxMC42NTI4IDE4LjA4NzkgMTAuOTY1NkwxMy4wODcgMTUuNzY1NkMxMi43NjExIDE2LjA3ODQgMTIuMjM0MyAxNi4wNzg0IDExLjkwODQgMTUuNzY1NloiIGZpbGw9IiM0NDQ0NDQiLz4KICAgICAgICAgICAgICAgICAgICAgICAgPC9zdmc+") no-repeat calc(100% - .5rem) 0.5em/1.5rem 1.5rem !important;
    }
  }
  