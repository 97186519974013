:root {
  --stgy-input-fontsize: 0.82rem;
}

.stgy-input-label {
  font-size: 0.75rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
  padding-left: 0.2rem;
}

.stgy-input[type="range"] {
  width:100%;      
}

.stgy-input[type="text"] {
  font-size: var(--stgy-input-fontsize);
  height: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: var(--stgy-gray-300);
  border-radius: 5px;
  padding: 5px 0px 5px 10px;
  }
  

.stgy-input[type="number"] {
  font-size: var(--stgy-input-fontsize);
  height: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: var(--stgy-gray-300);
  border-radius: 5px;
  padding: 5px 0px 5px 10px;
  }

  .stgy-input[type="email"] {
    font-size: var(--stgy-input-fontsize);
    height: 2rem;
    border-style: solid;
    border-width: 1px;
    border-color: var(--stgy-gray-300);
    border-radius: 5px;
    padding: 5px 0px 5px 10px;
    }
  
  .stgy-input[type="password"] {
    font-size: var(--stgy-input-fontsize);
    height: 2rem;
    border-style: solid;
    border-width: 1px;
    border-color: var(--stgy-gray-300);
    border-radius: 5px;
    padding: 5px 0px 5px 10px;
    }
  
  .stgy-input[type="date"] {
    font-size: var(--stgy-input-fontsize);
    height: 2rem;
    border-style: solid;
    border-width: 1px;
    border-color: var(--stgy-gray-300);
    border-radius: 5px;
    padding: 5px 0px 5px 10px;
    }

  .stgy-textarea {
    font-size: var(--stgy-input-fontsize);
    line-height: 1.2rem !important;
    border-style: solid;
    border-width: 1px;
    border-color: var(--stgy-gray-300);
    border-radius: 5px;
    padding: 5px 0px 5px 10px !important;
    }

  .stgy-fullwidth {
    width:100%;
  }