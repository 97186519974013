:root {

    //Colors
    --stgy-primary-color: rgb(255,201,14);
    --stgy-primary-color-100: rgba(255,201,14,0.05);
    --stgy-primary-color-200: rgba(255,201,14,0.1);
    --stgy-primary-color-300: rgba(255,201,14,0.2);
    --stgy-primary-color-400: rgba(255,201,14,0.5);
    --stgy-section-background: rgb(248,248,248);
    --stgy-section-background-border: rgb(240,240,240);
    --stgy-white: #ffffff;
    --stgy-gray-100: #f9f9f9;
    --stgy-gray-200: #eeeeee;
    --stgy-gray-300: #dedede;
    --stgy-gray-400: #444444;
    --stgy-black: #000000;
    --stgy-table-border: rgb(228,228,228);
  
    //Fonts
    --stgy-font-family: 'Inter', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    --stgy-font-size-small: 0.8125rem;
    --stgy-font-size-medium: 1rem;
    --stgy-font-size-large: 1.25rem;
    --stgy-font-weight-normal: 400;
    --stgy-font-weight-semibold: 600;
  

    --stgy-sidebar-width: 16.5rem;
  }
  