.e-gantt .e-gantt-chart .e-gantt-child-progressbar-inner-div {
  background-color: #7c7c7c !important;
  border: 0px;
  border-radius: 3px;
}

.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
  background: #9e9e9e !important;
  outline: 1px solid #777777 !important;
  border-radius: 4px;
}

/* 
.e-toolbar {
  background: #6d0f0f !important;
}

.e-toolbar .e-toolbar-item .e-tbar-btn {
  background: #690a0a !important;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87) !important;
  border: none;
}

  .e-toolbar .e-toolbar-item .e-tbar-btn {
    color:  #c4c4c4 !important;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: 4px 0;
    min-height: 0;
    min-width: 0;
    padding: 0 1.5px;
    border-radius: 2px;
    line-height: 25px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-transform: none;
}

.e-btn, .e-css.e-btn {
  -webkit-tap-highlight-color: transparent;
  background: #280c5e !important;
  border-color: transparent;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}


.e-toolbar {
  -webkit-tap-highlight-color: rgba(161, 37, 37, 0);
  background: #c00c0c !important;
  border: 1px none rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.e-gantt .e-gantt-toolbar {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-style: solid;
  border-width: 1px 1px 0;
  background: #280c5e !important;
}

.e-toolbar.e-control[class*=e-toolbar] {
  box-sizing: content-box;
  background: #280c5e !important;
} */