  
  .stgy-modal-content,
  .stgy-modal-card {
    margin: 0 20px;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
    width: 100%;
  }
  
  @media screen and (min-width: 769px) {
    .stgy-modal-content,
    .stgy-modal-card {
      margin: 0 auto;
      max-height: calc(100vh - 40px);
      width: 640px;
    }
  }
  
  .stgy-modal-close {
    background: none;
    height: 40px;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;
  }
  
  .stgy-modal-card {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    -ms-overflow-y: visible;
  }
  
  .stgy-modal-card-head {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
  }

  .stgy-modal-card-head {
    border-bottom: 1px solid #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 40px;
  }
  
  .stgy-modal-card-title {
    color: #363636;
    flex-grow: 1;
    flex-shrink: 0;
    line-height: 1;
    font-size: 0.96rem;
  }
  
  .stgy-modal-card-foot {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 1px solid #dbdbdb;
    align-items: center;
    background-color: rgb(245, 245, 245);
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    height: 46px;
  }
  
  .stgy-modal-card-foot .button:not(:last-child) {
    margin-right: 0.5em;
  }
  
  .stgy-modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: white;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
  }
