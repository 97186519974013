.select-checkbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: none;
  padding: 0;
  height:"200px";
}

.select-checkbox option::before {
  content: "\2610";
  width: 1.3em;
  text-align: center;
  display: inline-block;

}
.select-checkbox option:checked::before {
  content: "\2611";
  appearance: "none"
}


.select-checkbox:focus option:checked {
    background-color: green !important;
    color: blue !important;
  }
