input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection,
.e-float-input.e-small textarea::selection,
.e-float-input textarea::selection {
  background: rgb(175, 175, 175) !important;

}

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input {
    height: 5px !important;
    padding:0 !important;
}



.nboxoff.e-control.e-numerictextbox.e-input{ 
    pointer-events:none; 
}

.nboxon.e-control.e-numerictextbox.e-input{ 
    pointer-events:auto; 
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left)
{
    border: none !important;
}