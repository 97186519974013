button.stgy-button-nofill {
    border-radius: 5px;
    padding: 4px 10px 4px 10px;
    font-size: 0.85rem;
    border-width: 1px;
    border-style: solid;
    font-weight: 500;
    float: right;
}

button.stgy-button-nofill:hover {
    box-shadow: 0 4px 4px 0 rgba(100, 100, 100, 0.24), 0 10px 20px 0 rgba(100, 100, 100, 0.19);
}

button.stgy-button-logout {
    border-radius: 5px;
    padding: 4px 7px 4px 7px;
    font-size: 0.9rem;
    border-width: 1px;
    border-style: solid;
    background-color: #F4476A;
    border-color: #F4476A;
    color: white;
    font-weight: 400;
    float: right;
}

button.stgy-button-logout:hover {
    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.24), 0 10px 20px 0 rgba(0,0,0,0.19);
  }

button.stgy-save {
    border-radius: 5px;
    padding: 4px 9px 3px 9px;
    font-size: 0.80rem;
    border-width: 1px;
    border-style: solid;
    background-color: #4AC990;
    border-color: #4AC990;
    color: white;
    font-weight: 300;
    margin-right: 0.5rem;
}

button.stgy-save:hover {
    box-shadow: 0 4px 4px 0 rgba(100, 100, 100, 0.24), 0 10px 20px 0 rgba(100, 100, 100, 0.19);
  }

  button.stgy-save-large {
    border-radius: 5px;
    padding: 5px 11px 4px 11px;
    font-size: 0.92rem;
    border-width: 1px;
    border-style: solid;
    background-color: #4AC990;
    border-color: #4AC990;
    color: white;
    font-weight: 300;
    margin-right: 0.5rem;
}

button.stgy-save-large:hover {
    box-shadow: 0 4px 4px 0 rgba(100, 100, 100, 0.24), 0 10px 20px 0 rgba(100, 100, 100, 0.19);
  }

button.stgy-cancel {
    border-radius: 5px;
    padding: 4px 6px 3px 6px;
    font-size: 0.80rem;
    border-width: 1px;
    border-style: solid;
    color: rgb(80, 80, 80);
    border-color: rgb(100, 100, 100);
    background-color: rgb(250, 250, 250);
    font-weight: 300;
}

button.stgy-cancel:hover {
    box-shadow: 0 4px 4px 0 rgba(100, 100, 100, 0.24), 0 10px 20px 0 rgba(100, 100, 100, 0.19);
}

button.stgy-add {
    border-radius: 5px;
    padding: 4px 6px 3px 6px;
    font-size: 0.80rem;
    border-width: 1px;
    border-style: none;
    font-weight: 300;
    margin-right: 0.5rem;
}

button.stgy-add:hover {
    box-shadow: 0 4px 4px 0 rgba(100, 100, 100, 0.24), 0 10px 20px 0 rgba(100, 100, 100, 0.19);
}


button.stgy-delete {
    border-radius: 5px;
    padding: 4px 6px 3px 6px;
    font-size: 0.80rem;
    border-width: 1px;
    border-style: solid;
    border-color: #fa3159;
    color: white;
    background-color: #F4476A;
    font-weight: 300;
}

button.stgy-delete:hover {
    box-shadow: 0 4px 4px 0 rgba(100, 100, 100, 0.24), 0 10px 20px 0 rgba(100, 100, 100, 0.19);
}