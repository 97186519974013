

.stepper-custom-style .e-primary.e-inherit.e-input-group, .e-primary.e-inherit.e-input-group.e-control-wrapper, .e-primary.e-inherit.e-float-input, .e-primary.e-inherit.e-float-input.e-input-group, .e-primary.e-inherit.e-float-input.e-control-wrapper, .e-primary.e-inherit.e-float-input.e-input-group.e-control-wrapper, .e-primary.e-inherit.e-input-group:not(.e-disabled) .e-input-group-icon:hover, .e-primary.e-inherit.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    background: #dd0d0d ;
    color: #fff;
  }

  .stepper-custom-style .e-stepper .e-step-valid .e-indicator {
    background: #cccccc ;
    color: #fff;
  }



.stepper-custom-style .e-stepper .e-step-completed .e-step,
.stepper-custom-style .e-stepper .e-step-inprogress .e-step,
.stepper-custom-style .e-stepper .e-step-completed .e-indicator,
.stepper-custom-style .e-stepper .e-step-inprogress .e-indicator,
.stepper-custom-style .e-stepper .e-step-completed .e-step-indicator,
.stepper-custom-style .e-stepper .e-step-inprogress .e-step-indicator {
  background: #03af1a;
  color: #fff;
}


/* .stepper-custom-style .e-stepper .e-step-selected .e-indicator {
  background: #861c1c;
} */

/* Annimated Line */
.stepper-custom-style .e-stepper .e-stepper-progressbar > .e-progressbar-value {
  background-color: #9b9b9b;
}
/* Circle around Icon */
.stepper-custom-style .e-stepper:not(.e-steps-focus) .e-step-selected .e-step {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #03af1a, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #03af1a, 0 0 0 8px #fff;
}



.stepper-custom-style .e-stepper:not(.e-steps-focus) .e-step-valid.e-step-selected .e-step,
.stepper-custom-style .e-stepper .e-step-valid.e-step-focus .e-step,
.stepper-custom-style .e-stepper .e-step-valid.e-step-label-only.e-step-focus .e-label,
.stepper-custom-style .e-stepper .e-step-valid.e-step-text-only.e-step-focus .e-text,
.stepper-custom-style .e-stepper .e-step-valid.e-step-template.e-step-focus {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #03af1a, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #03af1a, 0 0 0 8px #fff;
}

.stepper-custom-style .e-stepper:not(.e-steps-focus) .e-step-selected .e-step {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ffffff, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ffffff, 0 0 0 8px #fff;
}

/* Text Font Color */
.stepper-custom-style .e-stepper .e-step-selected:not(.e-step-error) .e-text,
.stepper-custom-style .e-stepper .e-step-selected:not(.e-step-error) .e-label,
.stepper-custom-style .e-stepper .e-step-completed:not(.e-step-error) .e-text,
.stepper-custom-style .e-stepper .e-step-completed:not(.e-step-error) .e-label {
  color: #474747;
}

.stepper-custom-style .e-stepper:not(.e-step-type-label) .e-indicator:hover,
.stepper-custom-style .e-stepper:not(.e-step-type-label) .e-step:hover {
  color: #fff;
  background: #cecece;
}

.stepper-custom-style .e-stepper:not(.e-step-type-label) .e-step-selected .e-indicator:active,
.stepper-custom-style .e-stepper:not(.e-step-type-label) .e-step-selected .e-step:active {
  background: #cecece;
}