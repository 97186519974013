.stgy-sidebar {
    position: fixed;
    top: 0;
    display: flex;
    flex: 0 0 var(--stgy-sidebar-width);
    width: var(--stgy-sidebar-width);
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.25rem;
    background: linear-gradient(to left, var(--stgy-gray-100), var(--stgy-white));
    border-right: 1px solid var(--stgy-gray-200);
    
    .stgy-sidebar__top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 1.25rem;
  
  
      > * {
        width: 100%;
      }
    }
  
  
    .stgy-sidebar__logo {
      display: inline-flex;
      align-self: stretch;
      padding: 0.25rem;
      justify-content: flex-start;
      align-items: center;
      gap: 0.6875rem;
      user-select: none;
    }
  
  
    .stgy-sidebar__logo-icon {
      position: relative;
      flex: 0 0 3rem;
      width: 3rem;
      height: 3rem;
      border-radius: 0.5rem;
      background-color: var(--stgy-primary-color);
      overflow: hidden;
  
  
      svg,
      img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  
  
    .stgy-sidebar__logo-name {
      flex: 1 1 auto;
      font-size: var(--stgy-font-size-large);
      font-weight: var(--stgy-font-weight-semibold);
      line-height: 1;
    }
  
  
    .stgy-sidebar__primary-nav {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
  
    .stgy-sidebar__nav-item {
      position: relative;
      display: inline-flex;
      align-self: stretch;
      gap: 1.4375rem;
      padding: 0.625rem 1rem;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0.25rem;
      overflow: hidden;
      user-select: none;
  
  
      &--active {
        background: linear-gradient(to right, var(--stgy-primary-color-300), var(--stgy-primary-color-100) );
  
  
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0.25rem;
          height: 100%;
          background-color: var(--stgy-primary-color);
        }
      } 
    }
  
    .stgy_reportmemu {
      div:hover {
        font-weight: 600;
      }
    }

  
    .stgy-sidebar__nav-item-icon {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
  
  
      svg,
      img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  
  
    .stgy-sidebar__nav-item-label {
      font-size: var(--stgy-font-size-small);
    }
  
  
    .stgy-sidebar__btm {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
  
  
      > * {
        width: 100%;
      }
    }
  
  
    .stgy-sidebar__secondary-nav {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
  
    .stgy-sidebar__sep {
      align-self: stretch;
      height: 1px;
      background-color: var(--stgy-gray-300);
    }
  
  
    .stgy-sidebar__account {
      display: inline-flex;
      align-self: stretch;
      padding: 0.25em 0.5rem;
      justify-content: flex-start;
      align-items: center;
      gap: 0.9375rem;
      user-select: none;
    }
  
  
    .stgy-sidebar__account-icon {
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
    }
  
  
    .stgy-sidebar__account-icon-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: linear-gradient(to bottom, #d9d9d9, #737373);
    }
  
  
    .stgy-sidebar__account-icon-initials {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--stgy-white);
      font-size: 1rem;
      font-weight: bold;
      font-family: var(--stgy-font-family);
      line-height: 1;
      text-align: center;
    }
  
  
    .stgy-sidebar__account-name {
      font-size: var(--stgy-font-size-small);
      font-weight: var(--stgy-font-weight-semibold);
    }

    .stgy-sidebar__select {
      user-select: none;
    }
  
  
  }
  