.stgy-normalize {
    height: 100%;
    overflow-y: auto;


    body {
        height: 100%;
        margin: 0;
    }
}


.stgy-wrapper {
    line-height: 1.5; 
    -webkit-text-size-adjust: 100%; 
    tab-size: 4; 
    overflow-y: hidden !important;


    * {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-color: theme('borderColor.DEFAULT', currentColor);
    }


    hr {
        height: 0; 
        color: inherit;
        border-top-width: 1px;
    }


    /*
    Add the correct text decoration in Chrome, Edge, and Safari.
    */
    abbr:where([title]) {
        text-decoration: underline dotted;
    }


    /*
    Remove the default font size and weight for headings.
    */


    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit;
    }


    /*
    Reset links to optimize for opt-in styling instead of opt-out.
    */


    a {
        color: inherit;
        text-decoration: inherit;
    }


    /*
    Add the correct font weight in Edge and Safari.
    */


    b,
    strong {
        font-weight: bolder;
    }


    /*
    1. Use the user's configured `mono` font family by default.
    2. Correct the odd `em` font sizing in all browsers.
    */


    code,
    kbd,
    samp,
    pre {
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        font-size: 1em;
    }


    /*
    Add the correct font size in all browsers.
    */


    small {
        font-size: 80%;
    }


    /*
    Prevent `sub` and `sup` elements from affecting the line height in all browsers.
    */


    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }


    sub {
        bottom: -0.25em;
    }


    sup {
        top: -0.5em;
    }


    /*
    1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
    2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
    3. Remove gaps between table borders by default.
    */


    table {
        text-indent: 0; 
        border-color: inherit;
        border-collapse: collapse;
    }


    /*
    1. Change the font styles in all browsers.
    2. Remove the margin in Firefox and Safari.
    3. Remove default padding in all browsers.
    */


    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        // font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
        margin: 0;
        padding: 0;
    }


    /*
    Remove the inheritance of text transform in Edge and Firefox.
    */


    button,
    select {
        text-transform: none;
    }


    /*
    1. Correct the inability to style clickable types in iOS and Safari.
    2. Remove default button styles.
    */


    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: button;
        background-color: transparent;
        background-image: none;
    }


    /*
    Use the modern Firefox focus style for all focusable elements.
    */


    :-moz-focusring {
        outline: auto;
    }


    /*
    Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
    */


    :-moz-ui-invalid {
        box-shadow: none;
    }


    /*
    Add the correct vertical alignment in Chrome and Firefox.
    */


    progress {
        vertical-align: baseline;
    }


    /*
    Correct the cursor style of increment and decrement buttons in Safari.
    */


    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        height: auto;
    }


    /*
    1. Correct the odd appearance in Chrome and Safari.
    2. Correct the outline style in Safari.
    */


    [type='search'] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
    }


    /*
    Remove the inner padding in Chrome and Safari on macOS.
    */


    ::-webkit-search-decoration {
        -webkit-appearance: none;
    }


    /*
    1. Correct the inability to style clickable types in iOS and Safari.
    2. Change font properties to `inherit` in Safari.
    */


    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
    }


    /*
    Add the correct display in Chrome and Safari.
    */


    summary {
        display: list-item;
    }


    /*
    Removes the default spacing and border for appropriate elements.
    */


    blockquote,
    dl,
    dd,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    figure,
    p,
    pre {
        margin: 0;
    }


    fieldset {
        margin: 0;
        padding: 0;
    }


    legend {
        padding: 0;
    }


    ol,
    ul,
    menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }


    /*
    Prevent resizing textareas horizontally by default.
    */


    textarea {
        resize: vertical;
    }


    /*
    1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
    2. Set the default placeholder color to the user's configured gray 400 color.
    */


    input::placeholder,
    textarea::placeholder {
        opacity: 1;
        color: #9ca3af;
    }


    /*
    Set the default cursor for buttons.
    */


    button,
    [role="button"] {
        cursor: pointer;
    }


    /*
    Make sure disabled buttons don't get the pointer cursor.
    */
    :disabled {
        cursor: default;
    }


    /*
    1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
    2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
       This can trigger a poorly considered lint error in some tools but is included by design.
    */


    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
        display: block;
        vertical-align: middle;
    }


    /*
    Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
    */


    img,
    video {
        max-width: 100%;
        height: auto;
    }


    /* Make elements with the HTML hidden attribute stay hidden by default */
    [hidden] {
        display: none;
    }
}

  