
.stgy-section {
  padding-left: 5%;
  padding-right: 5%;
  margin:0;
  min-height: 100vh;
  margin: 0;
  user-select: none;
}

.stgy-table {
  width: 100%;
}

.stgy-section-item{
  background-color: var(--stgy-section-background); 
  border: 1px solid var(--stgy-section-background-border);
  border-radius: 8px;
}

.stgy-table tr {
  line-height: 2rem;
  border-top: 1px solid var(--stgy-table-border);
}

.stgy-table td {
  vertical-align: top;
}

.stgy-table th {
  vertical-align: middle;
  font-weight: 500;
  font-size: 0.92rem;
}

.stgy-table tr:hover {
  background-color: #f0f0f0;
}

.stgy-level{
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  user-select: none;
}

.stgy-card-content{
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  user-select: none;
}

.stgy-sprintheader{
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  user-select: none;
}

.stgy-sprintheader input{
  clear: both;
  float: right;
  justify-content: flex-end;
  background-color: red;
  align-items: flex-end;
}


.stgy-card-checkbox{
  position: absolute;
  top: 10px;
  right: 10px;
  accent-color: white;
}

.stgy-ul {
  display: flex;
  overflow: hidden;
  border: none;
  background-color: none;
  font-size: 0.96rem;
  cursor: pointer;
}

.stgy-tab li {
  float:left;
  background-color: none;
  border: none;
  outline: none;
  padding: 0px 0px;
}

.stgy-tab li:hover {
  background-color:green;
}

.stgy-ul li.is-active {
  border-bottom: 1px solid black;
}



.stgy-body {
    flex: 1 1 auto;
    margin-left: 16.5rem;
    width: 100%;
    
    /*
    Placeholder styles:
    */
    padding: 0;
  
    .page-header-text {
      font-size: 1.4rem;
      font-weight: 600;
      padding-top: 1.7rem;
      color: "rgb(90,90,90)"
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 1.5rem;
    }
  
  
    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 1.25rem;
    }
  
  
    h3 {
      font-size: 1.125rem;
      font-weight: 600;
      margin-top: 1.25rem;
    }
  
  
    h4 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 1.25rem;
    }
  
  
    h5 {
      font-size: 0.875rem;
      font-weight: 600;
      margin-top: 1.25rem;
    }
  
  
    h6 {
      font-size: 0.75rem;
      font-weight: 600;
      margin-top: 1.25rem;
    }
  
  
    p {
      margin-top: 1rem;
    }
  

  }
  